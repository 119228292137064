import React from "react"
import ShareToMeta from "./ShareToMeta"
import ShareToYoutube from "./ShareToYoutube"
import ShareToTiktok from "./ShareToTiktok"
import ShareToInstagram from "./ShareToInstagram"

function ShareVideo({shareVideo = null, onClose, openShareTable}) {
    
    if (!shareVideo) return null

    const shareToInstagram = shareVideo.media === "instagram"
    const shareToFacebook = shareVideo.media === "facebook"
    const shareToYoutube = shareVideo.media === "youtube"
    const shareToTiktok = shareVideo.media === "tiktok"
    // and more coming

    if (shareToInstagram) {
        return (
            <ShareToInstagram
                shareVideo={shareVideo}
                onClose={onClose}
                openShareTable={openShareTable}
            />
        )
    }

    if (shareToFacebook) {
        return (
            <ShareToMeta
                shareVideo={shareVideo}
                onClose={onClose}
                openShareTable={openShareTable}
            />
        )
    }

    if (shareToYoutube) {
        return (
            <ShareToYoutube
                shareVideo={shareVideo}
                onClose={onClose}
                openShareTable={openShareTable}
            />
        )
    }

    if (shareToTiktok) {
        return (
            <ShareToTiktok
                shareVideo={shareVideo}
                onClose={onClose}
                openShareTable={openShareTable}
            />
        )
    }

    return null
}

export default ShareVideo

import React, {useState, useEffect} from "react"
import Config from "../../../../utility/Config"
import Backend from "../../../../utility/Backend"
import { useLoginSession } from "../../../../stores/loginSession"
import { useFeedbackMessage } from "../../../../stores/FeedbackMessage"
import { useMutateByRegex } from "../../../../utility/Utilities"
import { useExportsModal } from "../../../../stores/ExportsModalContext"
import PrepareExportModal from "./PrepareExportModal"
import facebookLogo from "../../../../img/facebook.png"
import instagramLogo from "../../../../img/instagram.png"
import tiktokLogo from "../../../../img/tiktok.png"
import snapchatLogo from "../../../../img/snapchat.png"
import "./EditingControls.css";
import { CgExport } from "react-icons/cg";

export function getSocialMediaLogo (preset) {
    if (preset.includes("instagram")) return instagramLogo
    if (preset.includes("facebook")) return facebookLogo
    if (preset.includes("tiktok")) return tiktokLogo
    if (preset.includes("snapchat")) return snapchatLogo
}

export function getAspectRatioName (aspectRatio) {
    if (aspectRatio === "9:16") return "Mobile (9:16)"
    if (aspectRatio === "4:5") return "Tablet (4:5)"
    if (aspectRatio === "1:1") return "Square (1:1)"
}

function PrepareExport ({
    playlist, 
    fromTimestamp=null, 
    toTimestamp=null, 
    onResetPlayback=null}) {

    const {token} = useLoginSession()
    const {showFeedback,} = useFeedbackMessage()
    const {setIsOpen: setIsExportsModalOpen} = useExportsModal()
    const mutateByRegex = useMutateByRegex()
    const isShl = Config.association === "SHL"
    const isDemo = Config.association === "EL"

    const [preset, setPreset] = useState(null)
    const [graphic, setGraphic] = useState(null)
    const [aspectRatio, setAspectRatio] = useState(null)
    const [fps, setFps] = useState(null)
    const [cropping, setCropping] = useState("center")
    const [bitrate, setBitrate] = useState(null)
    const [audioBitrate, setAudioBitrate] = useState(null)
    const [audio, setAudio] = useState(null)
    const [clipTransitions, setClipTransitions] = useState("smoothleft")
    const [aiClipping, setAiClipping] = useState(false)
    const [prepareExportModal, setPrepareExportModal] = useState(false)
    
    useEffect(() => {
        if (preset) { 
            if (preset === "instagram_feed") {
                setAspectRatio("1:1")
                setBitrate(3.5)
            }
            if (preset === "instagram_reels") {
                setAspectRatio("9:16")
                setBitrate(3.5)
            }
            if (preset === "facebook_feed") {
                setAspectRatio("4:5")
                setBitrate(4)
            }
            if (preset === "tiktok") {
                setAspectRatio("9:16")
                setBitrate(4)
            }
            if (preset === "snapchat") {
                setAspectRatio("9:16")
                setBitrate(3.5)
            }
            if (isShl || isDemo) {
                setAudio(1)
            } 
            if (multipleClips) {
                setClipTransitions("smoothleft")
            }
            if (!isShl) {
                setCropping("smart")
            }
            setFps(25) 
        } else {
            setPreset(null)
            setGraphic(null)
            setAspectRatio(null)
            setFps(null)
            setCropping("center")
            setBitrate(null)
            setAudio(null)
            setClipTransitions("smoothleft")
            setAiClipping(false)
        }
    }, [preset])
    
    useEffect(() => {
        if (!aspectRatio) setCropping("center")
        if (aspectRatio && !isShl) setCropping("smart")
    }, [aspectRatio])

    const resetPrepareExport = () => {
        setPrepareExportModal(false)
        setPreset(null)
        setGraphic(null)
        setFps(null)
        setAspectRatio(null)
        setCropping("center")
        setBitrate(null)
        setAudio(null)
        setClipTransitions("smoothleft")
        setAiClipping(false)
        if (onResetPlayback) onResetPlayback()
    }

    const multipleClips = playlist.events.length > 1
    // disable prepare export for compilation that longer than 15 mins
    const videoExceedExportLimit = Config.maximumInlineExportSeconds < (toTimestamp-fromTimestamp)/1000

    async function onClickQueueExport (e) {
        const description = playlist?.description || "Temporary playlist"

        const exportVideoObject = {
            destinations: [{
                type: "forzify_video_export",
                description: description
            }],
        }
        if (!multipleClips) exportVideoObject.playlist_query = `${playlist.events[0].video_asset_id}:${fromTimestamp}:${toTimestamp}`
        if (graphic) exportVideoObject.graphic_preset = graphic
        if (preset) exportVideoObject.output_preset = preset
        if (fps && preset) exportVideoObject.fps = fps
        if (aspectRatio) exportVideoObject.aspect_ratio = aspectRatio
        if (cropping) exportVideoObject.cropping = cropping
        if (multipleClips && clipTransitions) exportVideoObject.clip_transitions = clipTransitions
        if (bitrate) exportVideoObject.video_bitrate = bitrate * 1000000
        if (audioBitrate) exportVideoObject.audio_bitrate = audioBitrate
        if (audio) exportVideoObject.audio_tracks = parseInt(audio)
        if (aiClipping) exportVideoObject.ai_clipping = aiClipping
        // TODO will use these sometime soon
        // if (clipGraphics) exportVideoObject.clip_graphics = clipGraphics
        
        console.log(exportVideoObject);

        const {error} = await Backend.post(`/playlist/${playlist.id}/export`, JSON.stringify(exportVideoObject), {access_token: token})
        if (error) {
            showFeedback("error", `An error occurred attempting to export: ${error}`);
            console.error(error)
        }
        resetPrepareExport()
        mutateByRegex(/^\/video_export/)
        setPrepareExportModal (false)
        setIsExportsModalOpen(true)
    }

    return (
        <>
            <button disabled={videoExceedExportLimit} onClick={() => setPrepareExportModal(true)}>
                <CgExport className="icon-in-btn"/>
                Prepare export
            </button>
            {prepareExportModal && (
                <PrepareExportModal
                    graphic={graphic}
                    preset={preset}
                    aspectRatio={aspectRatio}
                    fps={fps}
                    cropping={cropping}
                    bitrate={bitrate}
                    audioBitrate={audioBitrate}
                    audio={audio}
                    clipTransitions={clipTransitions}
                    aiClipping={aiClipping}
                    setGraphic={setGraphic}
                    setPreset={setPreset}
                    setAspectRatio={setAspectRatio}
                    setFps={setFps}
                    setCropping={setCropping}
                    setBitrate={setBitrate}
                    setAudioBitrate={setAudioBitrate}
                    setAudio={setAudio}
                    setClipTransitions={setClipTransitions}
                    setAiClipping={setAiClipping}
                    onClickQueueExport={onClickQueueExport}
                    onCancel={resetPrepareExport}
                    multipleClips={multipleClips}
                    />
            )}
        </>
    )
}

export default PrepareExport
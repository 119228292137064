import React, { useEffect, useState } from "react"
import Backend from "../../utility/Backend"
import Config from "../../utility/Config"
import CustomModal from "../../components/CustomModal"
import SmallDropdown from "../../components/SmallDropdown"
import { useLoginSession } from "../../stores/loginSession"
import { showErrorMessage } from "../../utility/Utilities"
import classNames from "classnames"
import { FaInstagram } from "react-icons/fa"
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md"
import { MdOutlineSubtitles } from "react-icons/md"
import "./ShareVideo.css"

function ShareToInstagram({ shareVideo, onClose, openShareTable }) {
    const { token } = useLoginSession()
    const video = shareVideo.video

    const [instagramUser, setInstagramUser] = useState(null)
    const [loggingOutInstagram, setLoggingOutInstagram] = useState(false)
    const [isLoadingCredentials, setIsLoadingCredentials] = useState(true)
    const [error, setError] = useState(null)
    const [popupClosed, setPopupClosed] = useState(false)

    // Input fields for Reels and Stories
    const [mediaType, setMediaType] = useState("REELS") 
    const [caption, setCaption] = useState("")
    const [audioName, setAudioName] = useState("")
    const [shareToFeed, setShareToFeed] = useState(false)
    const [coverUrl, setCoverUrl] = useState("")

    const openInstagramLogin = () => {
        const leaguePath = window.location.pathname.split("/")[1]
        const instagramLoginUrl = `${Config.api}${leaguePath}/socials/credentials/instagram/v2/authorize?access_token=${token}`
        const { outerWidth: screenWidth, outerHeight: screenHeight, screenX, screenY } = window
        const [windowWidth, windowHeight] = [750, 750]
        const left = screenX + (screenWidth - windowWidth) / 2
        const top = screenY + (screenHeight - windowHeight) / 2

        const loginPopup = window.open(instagramLoginUrl, "_blank", `width=${windowWidth}, height=${windowHeight}, left=${left}, top=${top}`)

        const popupChecker = setInterval(() => {
            if (loginPopup.closed) {
                clearInterval(popupChecker)
                setPopupClosed(true)
                setIsLoadingCredentials(true)
            }
        }, 500)
    }

    const fetchInstagramCredential = async () => {
        try {
            const { data: instagramCredentials } = await Backend.get("/socials/credentials/instagram/v2")
            if (instagramCredentials && instagramCredentials.credentials.length > 0) {
                const credential = instagramCredentials.credentials[0]
                setInstagramUser(credential)
            } else {
                setInstagramUser(null)
            }
        } catch (error) {
            console.error("Failed to fetch Instagram credentials", error)
            setError("Failed to fetch Instagram credentials. Please try again.")
        } finally {
            setIsLoadingCredentials(false)
        }
    }

    useEffect(() => {
        if (popupClosed) {
            fetchInstagramCredential()
        }
    }, [popupClosed])

    const resetInstagramShare = () => {
        setInstagramUser(null)
        setCaption("")
        setAudioName("")
        setShareToFeed(false)
        setCoverUrl("")
        setLoggingOutInstagram(false)
        setError(null)
        setIsLoadingCredentials(false)
        setPopupClosed(false)
    }

    const onShareToInstagram = async () => {
        const shareToInstagramBody = {
            media_type: mediaType,
            video_url: video.download_link,
        }

        if (mediaType === "REELS") {
            // Add optional Reels fields
            shareToInstagramBody.caption = caption
            shareToInstagramBody.audio_name = audioName
            shareToInstagramBody.share_to_feed = shareToFeed
            shareToInstagramBody.cover_url = coverUrl
        }

        try {
            const response = await Backend.post(`/socials/share/instagram/v2/${instagramUser.instagram_business_id}`, JSON.stringify(shareToInstagramBody))
            if (response.error) {
                setError(response.error)
            } else {
                onClose()
                openShareTable()
            }
        } catch (error) {
            setError(error.message || "An unknown error occurred.")
        }
    }

    const onLogoutInstagram = async () => {
        try {
            await Backend.delete(`/socials/credentials/instagram/v2/${instagramUser?.instagram_business_id}`)
            resetInstagramShare()
        } catch (error) {
            setError(error)
        }
    }

    const instagramUserDetails = (
        <div className="user-logged-in">
            <div className="logged-in-title">Logged in as {instagramUser?.instagram_username}</div>
            {instagramUser ? (
                <div className="share-user-profile">
                    <div className="user-profile-and-name">
                        <img src={instagramUser.profile_picture_url} alt="profile img" />
                        <div>{instagramUser.instagram_username}</div>
                        <button onClick={() => setLoggingOutInstagram(true)} className="small-btn red-btn">Logout</button>
                    </div>
                    {loggingOutInstagram && (
                        <div className="confirm-logout show">
                            <div className="create-delete-credentials-title">Confirm logout?</div>
                            <div className="delete-credentials-btn">
                                <button onClick={onLogoutInstagram} className="red-btn small-btn">Logout</button>
                                <button onClick={() => setLoggingOutInstagram(false)} className="small-btn">Cancel</button>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="no-credentials-found">
                    <button onClick={openInstagramLogin} className="login-with-instagram-btn">
                        <FaInstagram />
                        <span style={{ marginLeft: "5px" }}>Login with Instagram</span>
                    </button>
                </div>
            )}
            {error && showErrorMessage("Please login to share", !instagramUser)}
            {error && <div className="share-login-error">{error}</div>}
        </div>
    )

    const onGenerateDescription = () => {
        setCaption("Generating description. Please wait...") 
        const generateTextBody = {
            original_title: video.description || "",  
            user_description: caption, 
            platform: "Instagram",
        }
    
        Backend.post("/socials/get_text_suggestion", JSON.stringify(generateTextBody))
            .then(({ data, error }) => {
                if (error) {
                    console.error("Failed to generate", error)
                    setCaption("Failed to generate description.")
                } else {
                    setCaption(data.description)
                }
            })
            .catch(err => {
                console.error("Error generating description:", err)
                setCaption("Error generating description.")
            })
    }
    

    const shareToInstagramForm = (
        <div className="share-video-form">
            <div className="input-container">
            <SmallDropdown
                value={mediaType}
                options={[
                    { value: "REELS", label: "Reels" },
                    { value: "STORIES", label: "Stories" }
                ]}
                onChange={(selectedOption) => setMediaType(selectedOption.value)}
                placeholder="Select Media Type"
            />

            </div>
            
            {mediaType === "REELS" && (
                <>
                    <div className="input-container">
                        <label className="input-title">
                        Share Reels to Feed
                        <small className="optional-text">Optional</small> 
                        </label>
                        <div className="checkbox-container">
                            <div
                                className={classNames("interaction-checkbox")}
                                onClick={() => setShareToFeed(!shareToFeed)}
                            >
                                {shareToFeed ? (
                                    <MdOutlineCheckBox className="checkbox-icon" />
                                ) : (
                                    <MdOutlineCheckBoxOutlineBlank className="checkbox-icon" />
                                )}
                                Share to Feed
                            </div>
                        </div>
                    </div>

                    <div className="input-container">
                        <label className="input-title">
                            Caption
                            <small className="optional-text">Optional</small>
                        </label>
                        <textarea
                            value={caption}
                            onChange={(e) => setCaption(e.target.value)}
                            className="share-video-title-input"
                            maxLength="2200"  
                            placeholder="Add a caption"
                            rows="3"  
                            style={{ resize: "vertical" }}  
                        />
                        <span className="char-count">{2200 - caption.length}</span>
                            <div onClick={onGenerateDescription} className="copy-title-btn">
                                <MdOutlineSubtitles />
                                Generate a description
                            </div>  
                    </div>



                    <div className="input-container">
                        <label className="input-title">
                            Audio Name
                            <small className="optional-text">Optional</small>
                        </label>
                        <input
                            type="text"
                            value={audioName}
                            onChange={(e) => setAudioName(e.target.value)}
                            className="share-video-title-input"
                            placeholder="Add audio name"
                        />
                    </div>

                    <div className="input-container">
                        <label className="input-title">
                            Cover URL
                            <small className="optional-text">Optional</small>
                        </label>
                        <input
                            type="text"
                            value={coverUrl}
                            onChange={(e) => setCoverUrl(e.target.value)}
                            className="share-video-title-input"
                            placeholder="Add a cover URL"
                        />
                    </div>

                </>
            )}

            <div className="input-container">
                <label className="input-title">Video URL to Download</label>
                <input
                    type="text"
                    value={video.download_link}
                    readOnly
                    className="share-video-title-input"
                />
            </div>
        </div>
    )

    return (
        <CustomModal isOpen={!!shareVideo} onRequestClose={onClose} className="share-modal">
            <div className="share-video-modal">
                <div className="share-video-title">Share video to Instagram</div>
                <div className="share-video-and-credentials">
                    <div className="share-video-review">
                        <video src={video.download_link} controls></video>
                    </div>
                    <div className="credentials-and-form">
                        {instagramUserDetails}
                        {shareToInstagramForm}
                        <div className="confirm-cancel-btn-cont right">
                            <button onClick={onShareToInstagram} disabled={!!error} className="share-to-social-media-btn green-btn">
                                Share to Instagram
                            </button>
                            <button onClick={onClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default ShareToInstagram

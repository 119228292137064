import React, {useState, useEffect} from "react"
import { useBackend } from "../../utility/Backend";
import Backend from "../../utility/Backend";
import { showErrorMessage } from "../../utility/Utilities";
import { RiFileCopyLine } from "react-icons/ri";
import { ImUser } from "react-icons/im";
import { MdOutlineSubtitles } from "react-icons/md";
import "./ShareVideo.css"

function FacebookCredential ({ fbLogin, selectedCredential, setSelectedCredential }) {

    const { data, mutate: mutateCredentials } = useBackend("/socials/credentials/facebook")
    const credentialsData = data || []
    const credential = credentialsData[0]
    
    const facebookId = credential?.facebook_user_id
    const credentialValid = credential?.is_valid || true

    const [credentialError, setCredentialError] = useState(null)

    const createNewFacebookCredentials = () => {
        const accessTokenBody = {
            facebook_user_access_token: fbLogin.access_token,
            expires_in: fbLogin.expires_in
        }
        const {error} = Backend.post("/socials/credentials/facebook", JSON.stringify(accessTokenBody), {})
        if (error) {
            setCredentialError(error)
            console.log("Failed to create new credentials,", error)
        } else {
            mutateCredentials(data)
            console.log("New Facebook credentials created")
        }
    }

    const updateFacebookCredentials = async () => {
        const accessTokenBody = {
            facebook_user_access_token: fbLogin.access_token, 
            expires_in: fbLogin.expires_in,
        }
        const {error} = await Backend.put("/socials/credentials/facebook/" + facebookId, JSON.stringify(accessTokenBody))
        if (error) {
            setCredentialError(error)
            console.log("Failed to update Facebook credentials,", error)
        } else {
            mutateCredentials(data)
            console.log("Facebook credentials updated");
        }
    }

    // TODO will find out if this needs to or where will be used
    // const deleteFacebookCredentials = async () => {
    //     const accessTokenBody = {
    //         facebook_user_access_token: fbLogin.access_token, 
    //         expires_in: fbLogin.expires_in,
    //     }

    //     const {error} = await Backend.delete("/socials/credentials/facebook/" + facebookId, JSON.stringify(accessTokenBody))
    //     if (error) {
    //         setCredentialError(data)
    //         console.log("Failed to delete Facebook credentials,", error)
    //     } else {
    //         mutateCredentials(data)
    //         console.log("Facebook credentials deleted", facebookId)
    //     }
    // }

    useEffect(async () => {
        if (selectedCredential) return
        if (credential) {
            await updateFacebookCredentials()
            setSelectedCredential(credential)
        } else {
            createNewFacebookCredentials()
        }
    }, [credential, selectedCredential])

    useEffect(() => {
        if (credential && !credentialValid) updateFacebookCredentials()
    }, [credential, credentialValid])

    if (credentialError) return (
        <div className="credential-error">
            {credentialError}
        </div>
    )

    if (!data || credentialsData.length === 0) return (
        <div className="loading-credential">
            <div className="loading-icon-cont">
                <div className="loading-download-icon">
                </div>
            </div>
        </div>
    )

    return (
        <div className="credentials-details-single">
            {credential && (
                <div className="credentials-details">
                    <div className="credentials-details-img">
                        <ImUser/>
                    </div>
                    <div className="credentials-details-username-id">
                        <div className="credentials-details-username">
                            <div>Username</div>
                            <span>:</span>
                            {credential.facebook_email_address}
                        </div>
                        <div className="credentials-details-id">
                            <div>Facebook id</div>
                            <span>:</span>
                            {credential.facebook_user_id}
                        </div>
                    </div>
                </div>
            )}
            {credentialError}
            {!credentialValid && <div className="credentials-expired">This credential is expired. Please select a valid credential.</div>}
            {!credentialValid && (
                <div className="share-testing-btn">
                    <button onClick={updateFacebookCredentials}>Update</button>
                </div>
            )}
        </div>
    )
}

function ShareToFacebook ({fbLogin, onClose, video, openShareTable}) {

    const mediaType = "video"
    const [selectedCredential, setSelectedCredential] = useState(null)
    const [description, setDescription] = useState("")
    const [textAreaRows, setTextAreaRows] = useState(1)
    const [sharingError, setSharingError] = useState(null)
    const [inputError, setInputError] = useState(false)

    const facebookId = selectedCredential?.facebook_user_id
    const credentialValid = selectedCredential?.is_valid

    useEffect(() => {
        if (!inputError) return
        if (inputError) {
            if (facebookId && credentialValid) setInputError(false)
        }
    }, [inputError, facebookId, credentialValid])

    const onShareToFacebook = async () => {

        if (!facebookId) {
            setInputError(true)
            return
        }

        if (!credentialValid) {
            setInputError(true)
            return
        }

        const shareToFacebookBody = {
            media_type: mediaType.toUpperCase(),
            file_url: video.download_link,
        }

        if (description) shareToFacebookBody.description = description

        const {error} = await Backend.post("/socials/share/facebook/" + facebookId, JSON.stringify(shareToFacebookBody))
        if (error) {
            setSharingError(error)
            console.error("Failed to post", error)
        } else {
            openShareTable()
            console.log("Sharing to Facebook", shareToFacebookBody)
        }
        onClose()
    }

    const onCopyOriginalDescription = () => {
        setTextAreaRows(2)
        setDescription(video.description)
    }

    const onGenerateDescription = () => {
        setDescription("Generating description. Please wait...")
        const generateTextBody = {
            original_title: video.description,
            user_description: "",
            platform: video.output_preset || "",
        }

        Backend.post("/socials/get_text_suggestion", JSON.stringify(generateTextBody))
            .then(({data, error}) => {
                if (error) {
                    console.error("Failed to generate", error);
                } else {
                    setTextAreaRows(7)
                    setDescription(data.description)
                }
            });
    }
    
    const facebookCredentials = (
        <div className="instagram-credentials">
            <div className="logged-in-title">Facebook credentials</div>
            {fbLogin ? (
                <FacebookCredential fbLogin={fbLogin} selectedCredential={selectedCredential} setSelectedCredential={setSelectedCredential}/>
            ) : (
                <div className="login-for-credentials">
                    Login to facebook to get credential
                </div>
            )}
            {inputError && showErrorMessage("Credential not found", !facebookId)}
        </div>
    )

    const shareVideoForm = (
        <div className="share-video-form">
            <div className="input-container">
                <label htmlFor="" className="input-title">Media type</label>
                <div>Video</div>
            </div>
            <div className="input-container">
                <label className="input-title">Description</label>
                <textarea
                    type="text"
                    rows={textAreaRows}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    className="share-video-title-input"
                    />
                <div className="share-video-title-btn">
                    <div onClick={onCopyOriginalDescription} className="copy-title-btn">
                        <RiFileCopyLine/>
                        Use original description
                    </div>
                    <div onClick={onGenerateDescription} className="copy-title-btn">
                        <MdOutlineSubtitles/>
                        Generate a description
                    </div>
                </div>
            </div>
            {showErrorMessage("Failed to post. " + sharingError + ". Please try again later.", sharingError)}
        </div>
    ) 

    return (
        <>
            {facebookCredentials}
            {shareVideoForm}
            <div className="confirm-cancel-btn-cont right">
                <button onClick={onShareToFacebook} disabled={inputError} className="share-to-social-media-btn green-btn">Share to Facebook</button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </>
    )
}

export default ShareToFacebook